import { createContext } from 'react';

const availableMajors = [
    'Anthropology (Archaeology)',
    'Anthropology (Biological Anthropology)',
    'Anthropology (Sociocultural Anthropology)',
    'Anthropology with a Concentration in Climate Change and Human Solutions',
    'Biological Anthropology',
    'Bioengineering',
    'Bioengineering (Biotechnology)', 
    'Bioengineering (Bioinformatics)', 
    'Bioengineering: BioSystems',
    'Biology with Specialization in Bioinformatics',
    'Molecular and Cell Biology',
    'Neurobiology',
    'Biochemistry and Cell Biology',
    'Ecology, Behavior and Evolution',
    'General Biology',
    'Human Biology',
    'Microbiology',
    'Molecular Biology',
    'Physiology and Neuroscience',
    'Black Diaspora and African American Studies',
    'Chemistry and Biochemistry',
    'Biochemistry',
    'Chemistry',
    'Environmental Chemistry',
    'Molecular Synthesis',
    'Pharmacological Chemistry',
    'Chinese Studies',
    'Classical Studies',
    'Cognitive Science',
    'Cognitive Science with Specialization in Clinical Aspects of Cognition',
    'Cognitive Science with Specialization in Design and Interaction',
    'Cognitive Science with Specialization in Language and Culture',
    'Cognitive Science with Specialization in Machine Learning and Neural Computation',
    'Cognitive Science with Specialization in Neuroscience',
    'Communication',
    'Computer Science',
    'Computer Science with Specialization in Bioinformatics',
    'Critical Gender Studies',
    'Data Science',
    'Business Economics',
    'Economics',
    'Economics and Mathematics',
    'Economics-Public Policy',
    'Education Sciences',
    'Computer Engineering',
    'Electrical Engineering',
    'Electrical Engineering and Society',
    'Engineering Physics',
    'Environmental Systems (Earth Sciences)',
    'Environmental Systems (Ecology, Behavior, & Evolution)',
    'Environmental Systems (Environmental Chemistry)',
    'Environmental Systems (Environmental Policy)',
    'Ethnic Studies',
    'German Studies',
    'Global Health',
    'Global South Studies',
    'Public Health',
    'Public Health with Concentration in Biostatistics',
    'Public Health with Concentration in Climate and Environmental Sciences',
    'Public Health with Concentration in Community Health Sciences',
    'Public Health with Concentration in Epidemiology',
    'Public Health with Concentration in Health Policy and Management Sciences',
    'Public Health with Concentration in Medicine Sciences',
    'History',
    'Human Developmental Sciences',
    'Human Developmental Sciences with a Specialization in Equity and Diversity',
    'Human Developmental Sciences with a Specialization in Healthy Aging',
    'International Studies - Anthropology',
    'International Studies - Economics',
    'International Studies - History',
    'International Studies - International Business',
    'International Studies - Linguistics',
    'International Studies - Literature',
    'International Studies - Philosophy',
    'International Studies - Political Science',
    'International Studies - Sociology',
    'Italian Studies',
    'Japanese Studies',
    'Jewish Studies',
    'Latin American Studies',
    'Latin American Studies with a Concentration in Mexico',
    'Latin American Studies with a Concentration in Migration and Border Studies',
    'Linguistics',
    'Linguistics (Specialization in Cognition and Language)',
    'Linguistics (Specialization in Language and Society)',
    'Linguistics: Language Studies (Specialization in Individual Language)',
    'Linguistics (Specialization in Speech and Language Sciences)',
    'Literatures in English',
    'Spanish Literature',
    'Literature/Writing',
    'World Literature and Culture',
    'Mathematics (Applied)',
    'Mathematics',
    'Mathematics and Economics',
    'Mathematics - Applied Science',
    'Mathematics - Computer Science',
    'Mathematics - Secondary Education',
    'Probability and Statistics',
    'Aerospace Engineering',
    'Mechanical Engineering',
    'Mechanical Engineering with a Specialization in Controls and Robotics',
    'Mechanical Engineering with a Specialization in Fluid Mechanics and Thermal Systems',
    'Mechanical Engineering with a Specialization in Materials Science and Engineering',
    'Mechanical Engineering with a Specialization in Mechanics of Materials',
    'Mechanical Engineering with a Specialization in Renewable Energy and Environmental Flows',
    'Music',
    'Interdisciplinary Computing and the Arts',
    'Music Humanities',
    'Chemical Engineering',
    'NanoEngineering',
    'Other',
    'Philosophy',
    'General Physics',
    'General Physics/ Secondary Education',
    'Physics',
    'Physics with Specialization in Astrophysics',
    'Physics - Biophysics',
    'Physics with Specialization in Computational Physics',
    'Physics with Specialization in Earth Sciences',
    'Physics with Specialization in Materials Physics',
    'Political Science',
    'Political Science/American Politics',
    'Political Science/Comparative Politics',
    'Political Science/Data Analytics',
    'Political Science/International Affairs',
    'Political Science/International Relations',
    'Political Science/Political Theory',
    'Political Science/Public Law',
    'Political Science/Public Policy',
    'Political Science/Race, Ethnicity, and Politics',
    'Psychology',
    'Psychology with a Specialization in Clinical Psychology',
    'Psychology with a Specialization in Cognitive Psychology ',
    'Psychology with a Specialization in Developmental Psychology',
    'Psychology with a Specialization in Human Health',
    'Psychology with a Specialization in Sensation and Perception',
    'Psychology with a Specialization in Social Psychology',
    'Cognitive and Behavioral Neuroscience',
    'Business Psychology',
    'Study of Religion',
    'Russian, East European & Eurasian Studies',
    'Geosciences',
    'Marine Biology',
    'Oceanic and Atmospheric Sciences',
    'Sociology - International Studies',
    'Sociology - American Studies',
    'Sociology - Science and Medicine',
    'Sociology - Economy and Society',
    'Sociology - Culture and Communication',
    'Sociology - Social Inequality',
    'Sociology - Law and Society',
    'Structural Engineering',
    'Dance',
    'Theatre',
    'Undeclared',
    'Undeclared - Humanities/Arts',
    'Undeclared - Physical Sciences',
    'Undeclared - Social Sciences',
    'Urban Studies and Planning',
    'Real Estate and Development',
    'Art History/ Criticism',
    'Media',
    'Speculative Design',
    'Studio'
];


const MajorsContext = createContext(availableMajors.sort());

export default MajorsContext;
